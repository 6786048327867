<template>
    
    <form role="form" @submit="onFormSubmit" name="agency-switch-form">
      
      <Select
        v-if="listAgencies.length > 0"
        :label="$t('hospitality.agency.switch.label')"
        :placeholder="$t('hospitality.agency.switch.select_placeholder')"
        :selectOptions="{
          options: listAgencies,
          getOptionLabel : option => option.name
        }"
        v-bind:value.sync="form.agency" 
        :labelAsColumn="false"
      />
      
      <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('hospitality.agency.switch.button') }}</button>
    </form>
</template>

<style>
  form[name=agency-switch-form] {
    display: flex;
  }

  form[name=agency-switch-form] > div {
    display: flex;
    margin-bottom: 0;
    flex-grow:1;
  }

  form[name=agency-switch-form] > div > label {
    margin-right:10px;
    line-height: 36px;
    margin-bottom: 0px;
  }

  form[name=agency-switch-form] > div > div {
    flex-grow:1;
  }

  form[name=agency-switch-form] > button {
    margin-left:10px;
  }
</style>

<script lang="ts"> 
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import Select from '@fwk-client/components/panels/input/Select.vue';
import * as Ladda from 'ladda';
import { languagesTypes } from '@fwk-client/store/types';

import { useAgencyAdmin } from '../../composables/useAgencyAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      Select
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { agencies, selectedAgency, selectAgency } = useAgencyAdmin(props, context);

      const form:any = reactive({
        agency: selectedAgency ? selectedAgency.value.agency : undefined
      })
    
      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var button:HTMLButtonElement|null = document.querySelector( 'form[name=agency-switch-form] button' );
        laddaSubmit = Ladda.create(button!);
      })

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        laddaSubmit!.start();
        selectAgency(form.agency._id).then(() => {
          laddaSubmit!.stop();
        });
      }

      const listAgencies = computed(() => {
        return agencies.value.map((agency:any) => {
          return agency.agency;
        })
      })

      return { 
        listAgencies,
        form,
        selectedAgency,
        onFormSubmit
      }
    }
})
</script>